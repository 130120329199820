import { Stack, Text, Title, useTheme } from "@bookingcom/bui-react";
import { SearchboxController, SearchBoxVerticalDefault } from "@bookingcom/flights-searchbox";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import FrequentlyAskedQuestions from "components/elements/FrequentlyAskedQuestions";
import UspBlock from "components/elements/UspBlock";
import React, { Profiler, useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { trackMetaLandingsCustomGoalsV2 } from "utils/experiments/track-meta-landings-v2";
import { Search } from "app/routes";
import AppShell from "../components/elements/AppShell";
import useClientMetrics from "hooks/useClientMetrics";
import useGlobalContext from "hooks/useGlobalContext";
import useLocaleContext from "hooks/useLocaleContext";
import useSearchbox from "hooks/useSearchbox";
import useTrackPageLoad from "hooks/useTrackPageLoad";
import { trackExperiment, trackExperimentStage, trackGoal } from "utils/et";
import NoJS from "./NoJS";
import StaticContext from "app/StaticContext/StaticContext";
import { isOfMetaOrigin, isOfPPCOrigin } from "utils/marketing-url-params";
import Frame from "components/elements/Frame";
import { HomeSearchHeader } from "components/elements/HomeSearchHeader";
import Container from "../components/elements/Container";
import {
  RemoteAppleCardIndex,
  RemoteGeniusLaunchSheet,
  RemoteGeniusLoyalty,
  RemoteRewardsLandSheet
} from "components/remote";
import CheapFlights from "components/elements/CheapFlights";
import useLandingPageEvents from "hooks/c360/useLandingPageEvents";
import HomeRecentSearchList from "components/elements/HomeRecentSearch/HomeRecentSearchList";
import NoSSR from "app/NoSSR";
import CrowdstrikeOutage from "components/elements/CrowdstrikeOutage";
import { FlightsInterlinking } from "components/elements/FlightsInterlinking";
import { customGoal, goal, stage, trackWithDefaultStage } from "@flights/et-universal";
import useIsInViewport from "hooks/useIsInViewport";
import EsimBanner from "ancillaries/components/Esim/EsimBanner";
import { getEsimEligibilityIndex, getFetchStatusIndex } from "ancillaries/store/esim/selectors";
import flights_ace_web_esim_banner, {
  useTrackEsimBannerStages
} from "utils/experiments/ace/flights_ace_web_esim_banner";
import { useEsimIndex } from "ancillaries/hooks/Esim/useEsimFetch";
import WinterDealsBanner from "components/elements/WinterDealsBanner";
import Wrapper from "components/atoms/Wrapper";
import { getUserInfo } from "store/user/selectors";
import WeekendGetaways from "components/elements/WeekendGetaways";
import { getWeekendGetaways } from "store/weekendGetaways/selectors";
import HorizontalDivider from "components/atoms/HorizontalDivider";
import { useSelector } from "react-redux";
import { useLatestSearch } from "hooks/useLatestSearch";
import { EUROPEAN_COUNTRIES, WINTER_DEALS_EXLUDED_COUNTRIES, WEEKEND_GETAWAYS_EXCLUDED_COUNTRIES } from "../constants";
import usePointOfSale from "hooks/usePointOfSale";
import { mcn } from "utils/mergeClassnames";
import styles from "./Home.module.css";

const Home = () => {
  const { searchHistory, userAgent, features, ipCountry = "" } = useGlobalContext();
  const staticContext = useContext(StaticContext);
  const theme = useTheme();
  const i18n = useI18n();
  const trackContextEventV2 = useTrackPageLoad("index");
  const {
    userAgent: { isWebview }
  } = useGlobalContext();
  const searchBoxProps = useSearchbox();
  const { isRTL } = useLocaleContext();
  const { trackRenderTime } = useClientMetrics();
  const trackLandingPageEvents = useLandingPageEvents();
  /** start - flights_ace_web_esim_banner */
  const isEsimEligible = !!useSelector(getEsimEligibilityIndex);
  const esimFetchStatus = useSelector(getFetchStatusIndex);
  const showEsimBanner = isEsimEligible && !!flights_ace_web_esim_banner.trackWithDefaultStage();
  const fetchEsim = useEsimIndex();
  const esimBannerRef = useRef<HTMLDivElement>(null);
  const eSimInViewport = useIsInViewport(esimBannerRef.current);
  /** end - flights_ace_web_esim_banner */
  const weekendGetaways = useSelector(getWeekendGetaways);
  useTrackEsimBannerStages(isEsimEligible);
  const userInfo = useSelector(getUserInfo);
  const weekendGetawaysRef = useRef<HTMLDivElement>(null);
  const isWeekendGetawaysInViewport = useIsInViewport(weekendGetawaysRef, [], { threshold: 0.5 });
  const pos = usePointOfSale() || "";

  const isWinterDealsEligible =
    !WINTER_DEALS_EXLUDED_COUNTRIES.includes(ipCountry) && !isOfMetaOrigin() && !isOfPPCOrigin();

  const isWeekendGetawaysEligible = !!features.FLIGHTS_WEB_CAT_WEEKEND_GETAWAYS_EXCLUDE_COUNTRIES
    ? !WEEKEND_GETAWAYS_EXCLUDED_COUNTRIES.includes(pos)
    : true;

  if (process.env.BUILD_TARGET === "server" && staticContext.set) {
    const { getInitialState } = require("../server/screens/home");
    staticContext.set({ getInitialState });
  }

  /** start - flights_ace_web_esim_banner */
  useEffect(() => {
    if (esimFetchStatus === "initial") {
      void fetchEsim();
    }
  }, [esimFetchStatus, fetchEsim]);

  useEffect(() => {
    if (showEsimBanner && userInfo) {
      flights_ace_web_esim_banner.stages.with_booking();
    }
  }, [showEsimBanner, userInfo]);

  useEffect(() => {
    if (eSimInViewport && userInfo) {
      flights_ace_web_esim_banner.goals.viewed_esim_banner();
    }
  }, [eSimInViewport, userInfo]);
  /** end - flights_ace_web_esim_banner */

  useEffect(() => {
    Search.preload();
    trackContextEventV2();
    trackGoal("flights_home_page_view");
    goal("tbu_vertical_home_page_view");
    trackLandingPageEvents("page_view", "homepage");
    stage("flights_web_cat_home_page_traffic_mdot_aa", 4);

    // track meta users going to index page
    trackMetaLandingsCustomGoalsV2(2, "mdot");
    trackExperimentStage("flights_web_a11y_searchbox", 1); // stage 1 - Desktop + Mdot Index + Search results - a11y searchbox
    trackExperimentStage("flights_web_a11y_searchbox", 3); // stage 3 - Mdot - Index - a11y searchbox

    trackExperimentStage("flights_web_search_header_ui_update_mdot", 1);
    trackExperimentStage("flights_web_search_header_ui_update_mdot", isOfMetaOrigin() ? 3 : 2);

    stage("cm_post_trip_add_rewards_landsheet_to_flights_web", 3);

    //mvsf_whs_launchpad_nav_products_reorder_mdot
    stage("mvsf_whs_launchpad_nav_products_reorder_mdot", 6);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isWinterDealsEligible) {
      stage("flights_web_cat_winter_deals_promo", 3);
    }
  }, [isWinterDealsEligible]);

  /** start: Autofill search box with the latest search parameters */
  const hasPrefilledSearchParams = !!(
    new URLSearchParams(window.location.search).get("from") && new URLSearchParams(window.location.search).get("to")
  ); // cross-sell, marketing
  const isEligibleForSearchboxAutoFill = !userAgent.isBot && searchHistory?.length > 0 && !hasPrefilledSearchParams;
  useEffect(() => {
    if (isEligibleForSearchboxAutoFill) {
      stage("flights_web_cat_autofill_last_search_params_mdot", 1);
    }
  }, [isEligibleForSearchboxAutoFill]);

  useEffect(() => {
    if (isWeekendGetawaysEligible) {
      if (pos === "us") {
        stage("flights_web_cat_weekend_breaks_mdot", 2);
      } else if (EUROPEAN_COUNTRIES.includes(pos)) {
        stage("flights_web_cat_weekend_breaks_mdot", 3);
      } else {
        stage("flights_web_cat_weekend_breaks_mdot", 4);
      }

      if (isWeekendGetawaysInViewport) {
        customGoal("flights_web_cat_weekend_breaks_mdot", 2);
      }
    }
  }, [isWeekendGetawaysEligible, isWeekendGetawaysInViewport, pos]);

  const searchBoxInitialValue = useLatestSearch(
    searchBoxProps.initialValue,
    searchHistory,
    isEligibleForSearchboxAutoFill && !!trackWithDefaultStage("flights_web_cat_autofill_last_search_params_mdot", 1)
  );
  /** end: Autofill search box with the latest search parameters */

  return (
    <Profiler id="screen" onRender={trackRenderTime}>
      <PageHeader />
      <AppShell noPadding>
        <Frame alignItems="center" direction="column" justifyContent="center" grow={1}>
          {!!trackExperiment("flights_web_search_header_ui_update_mdot") ? (
            <HomeSearchHeader />
          ) : (
            <Frame
              attributes={{
                style: {
                  backgroundColor: isWebview
                    ? theme.colors.color_background_base
                    : theme.colors.color_background_disabled_alt,
                  width: "100%"
                }
              }}
              pr={4}
              pl={4}
            >
              <NoJS />
              <Frame mt={4} mb={4}>
                <Stack gap={2}>
                  <Text variant="headline_1" tagName="h1">
                    {i18n.trans(t("flights_index_header_compare_book_ease"))}
                  </Text>
                  {!isWebview && (
                    <span style={{ color: theme.colors.color_foreground_alt }}>
                      <Text variant="body_1">{i18n.trans(t("flights_index_header_discover_next_dream"))}</Text>
                    </span>
                  )}
                </Stack>
              </Frame>
              <Frame mb={6}>
                <SearchboxController
                  i18n={i18n}
                  {...searchBoxProps}
                  initialValue={searchBoxInitialValue}
                  isRTL={isRTL}
                  onSearch={(searchParams) => {
                    trackLandingPageEvents("click", "searchbox");
                    searchBoxProps.onSearch(searchParams);
                  }}
                >
                  {() => <SearchBoxVerticalDefault />}
                </SearchboxController>
                <CrowdstrikeOutage />
              </Frame>
            </Frame>
          )}

          {isWinterDealsEligible && trackWithDefaultStage("flights_web_cat_winter_deals_promo", 1) ? (
            <WinterDealsBanner />
          ) : undefined}

          <NoSSR>
            <HomeRecentSearchList />
          </NoSSR>

          {isWeekendGetawaysEligible && trackWithDefaultStage("flights_web_cat_weekend_breaks_mdot", 1) ? (
            <>
              <Container innerRef={weekendGetawaysRef} className={mcn(styles.container, styles.mobileContainer)}>
                <WeekendGetaways />
              </Container>

              {weekendGetaways?.length ? (
                <HorizontalDivider height={theme.units.spacing_2x} color={theme.colors.color_background_base_alt} />
              ) : null}
            </>
          ) : null}

          <CheapFlights isMobile={true} />

          <RemoteAppleCardIndex />

          <RemoteGeniusLoyalty />

          {showEsimBanner && (
            <Container style={{ margin: `${theme.units.spacing_8x} auto`, alignItems: "start" }}>
              <Title title={i18n.trans(t("flights_genius_section_header"))} variant="headline_2" titleTagName="h2" />
              <Wrapper elementRef={esimBannerRef} noBorder noPadding>
                <EsimBanner context="index" />
              </Wrapper>
            </Container>
          )}

          <Frame attributes={{ style: { width: "100%" } }} mb={6}>
            <UspBlock hideBackground={!!trackExperiment("flights_web_search_header_ui_update_mdot")} />
          </Frame>

          <FlightsInterlinking />

          <Frame attributes={{ style: { width: "100%" } }} grow={1} p={4}>
            <FrequentlyAskedQuestions />
          </Frame>
        </Frame>
      </AppShell>
      <RemoteGeniusLaunchSheet />
      {trackWithDefaultStage("cm_post_trip_add_rewards_landsheet_to_flights_web", 1) ? (
        <RemoteRewardsLandSheet />
      ) : undefined}
    </Profiler>
  );
};

function PageHeader(props: { children?: React.ReactNode }) {
  const i18n = useI18n() as I18nChildContext;

  return (
    <Helmet>
      <title>
        {i18n.trans(
          t("mlx_flights_index_meta_title", {
            variables: { b_companyname: "Booking.com" }
          })
        )}
      </title>
      <meta name="description" content={i18n.trans(t("mlx_flights_index_meta_description"))} />
      <meta property="webview:header" content="regular" />
      <meta property="webview:title" content={""} />
      {props.children}
    </Helmet>
  );
}

export default Home;
export { PageHeader };
