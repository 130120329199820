import { useMemo } from "react";
import { UICabinClass } from "@flights/types";
import useCreateUrl from "hooks/useCreateUrl";

type WeekendGetawayUrlParams = {
  arrivalCityIata: string;
  departureCityIata: string;
  departureCountry: string;
  departureDate: string;
  returnDate: string;
  tripType: string;
  numberOfAdults: string;
  cabinClass: UICabinClass;
  pricePerPersonInCurrency: number;
};

const useGenerateWeekendGetawayCardUrl = ({
  arrivalCityIata,
  departureCityIata,
  departureCountry,
  departureDate,
  returnDate,
  tripType,
  numberOfAdults,
  cabinClass,
  pricePerPersonInCurrency
}: WeekendGetawayUrlParams) => {
  const { createUrlWithApiPrefix, enhanceURLSearchParams } = useCreateUrl();

  return useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const _tripType = tripType === "ROUND_TRIP" ? "ROUNDTRIP" : "ONEWAY";

    urlParams.set("to", arrivalCityIata);
    urlParams.set("from", `${departureCityIata}`);
    urlParams.set("fromCountry", departureCountry || "");
    urlParams.set("depart", departureDate || "");
    urlParams.set("return", returnDate || "");
    urlParams.set("type", tripType ? _tripType : "");
    urlParams.set("adults", numberOfAdults || "");
    urlParams.set("cabinClass", cabinClass || "");
    urlParams.set("ca_source", "weekend_getaways");
    urlParams.set("ca_price", pricePerPersonInCurrency?.toString() || "");
    urlParams.set("stops", "0");
    urlParams.set("sort", "CHEAPEST");

    enhanceURLSearchParams(urlParams);

    return createUrlWithApiPrefix(`/flights/${departureCityIata}-${arrivalCityIata}/?${urlParams.toString()}`);
  }, [
    tripType,
    arrivalCityIata,
    departureCityIata,
    departureCountry,
    departureDate,
    returnDate,
    numberOfAdults,
    cabinClass,
    pricePerPersonInCurrency,
    enhanceURLSearchParams,
    createUrlWithApiPrefix
  ]);
};

export { useGenerateWeekendGetawayCardUrl };
