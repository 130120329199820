import React, { useCallback, useMemo, useRef } from "react";
import { Accordion, Card, Divider, Grid, GridColumn, Stack, Title, Text, useViewport } from "@bookingcom/bui-react";
import { FaqItem, getFaqData } from "./FrequentlyAskedQuestionsData";
import { t, useI18n } from "@bookingcom/lingojs-react";
import styles from "./FrequentlyAskedQuestions.module.css";
import { useTrackLandingPageViewport } from "hooks/c360/useLandingPageEvents";

/**
 * FAQ block on Flights index page.
 */
const FrequentlyAskedQuestions = () => {
  const i18n = useI18n();
  const faqItemList: FaqItem[] = getFaqData(i18n);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { isSmall, isMedium } = useViewport();

  useTrackLandingPageViewport(scrollRef, "faq");

  const midPoint = useMemo(() => Math.ceil(faqItemList.length / 2), [faqItemList]);

  const FaqColumn = useCallback(
    ({ items }: { items: FaqItem[] }) => (
      <GridColumn size={6}>
        <Card>
          <Stack gap={2}>
            {items.map((faq, index) => (
              <React.Fragment key={index}>
                <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                  <Accordion
                    titleContent={<Title title={faq.question} variant="strong_1" attributes={{ itemProp: "name" }} />}
                  >
                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                      <Text variant="body_2" attributes={{ itemProp: "text" }}>
                        {faq.answer}
                      </Text>
                    </div>
                  </Accordion>
                </div>
                {index < items.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Stack>
        </Card>
      </GridColumn>
    ),
    []
  );

  const renderFaqGridWithAccordion = useCallback(() => {
    if (isSmall || isMedium) {
      return (
        <div itemScope itemType="https://schema.org/FAQPage">
          <Grid>
            <GridColumn size={12}>
              <Card>
                <Stack gap={2}>
                  {faqItemList.map((faq, index) => (
                    <React.Fragment key={index}>
                      <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <Accordion
                          titleContent={
                            <Title title={faq.question} variant="strong_1" attributes={{ itemProp: "name" }} />
                          }
                        >
                          <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                            <Text variant="body_2" attributes={{ itemProp: "text" }}>
                              {faq.answer}
                            </Text>
                          </div>
                        </Accordion>
                      </div>
                      {index < faqItemList.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </Stack>
              </Card>
            </GridColumn>
          </Grid>
        </div>
      );
    }

    return (
      <div itemScope itemType="https://schema.org/FAQPage">
        <Grid>
          <FaqColumn items={faqItemList.slice(0, midPoint)} />
          <FaqColumn items={faqItemList.slice(midPoint)} />
        </Grid>
      </div>
    );
  }, [FaqColumn, faqItemList, isMedium, isSmall, midPoint]);

  return (
    <div ref={scrollRef}>
      <Title
        title={i18n.trans(t("seo_flights_new_index_lp_faq_header"))}
        variant="headline_2"
        className={styles.title}
        titleTagName="h2"
      />
      {renderFaqGridWithAccordion()}
    </div>
  );
};
export default FrequentlyAskedQuestions;
