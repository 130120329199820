import React, { useEffect } from "react";
import { stage } from "@flights/et-universal";
import SpotlightV1And2 from "components/elements/CountrySpotlight/SpotlightV1And2";
import SpotlightV3 from "components/elements/CountrySpotlight/SpotlightV3";
import usePointOfSale from "hooks/usePointOfSale";
import useCountrySpotlightData from "components/elements/CountrySpotlight/useCountrySpotlightData";

const CountrySpotlight = () => {
  const pos = usePointOfSale();
  const { isBrCountryExp, isNaCountryExp, isVariantOneOrTwo, isVariantThree } = useCountrySpotlightData();

  useEffect(() => {
    if (pos === "us") {
      stage("flights_web_cat_country_spotlight_na_www_one", 2);
    } else if (pos === "ca") {
      stage("flights_web_cat_country_spotlight_na_www_one", 3);
    } else if (pos === "mx") {
      stage("flights_web_cat_country_spotlight_na_www_one", 4);
    } else if (pos === "br") {
      stage("flights_web_cat_country_spotlight_br_www_one", 2);
    } else {
      stage("flights_web_cat_country_spotlight_br_www_one", 3);
    }
  }, [pos]);

  if (!isBrCountryExp && !isNaCountryExp) {
    return null;
  }

  const renderVariant = () => {
    if (isVariantOneOrTwo) {
      return <SpotlightV1And2 />;
    }

    if (isVariantThree) {
      return <SpotlightV3 />;
    }

    return null;
  };

  return renderVariant();
};

export default CountrySpotlight;
