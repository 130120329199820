import { Box, useTheme, Text, Stack } from "@bookingcom/bui-react";
import React from "react";
import Container from "../Container";
import NoJS from "screens/NoJS";
import { SearchboxController } from "@bookingcom/flights-searchbox";
import { SearchBoxVerticalDefault } from "@bookingcom/flights-searchbox";
import { t, useI18n } from "@bookingcom/lingojs-react";
import useSearchbox from "hooks/useSearchbox";
import useLocaleContext from "hooks/useLocaleContext";
import useLandingPageEvents from "hooks/c360/useLandingPageEvents";

const offset = 30;

const HomeSearchHeader = () => {
  const theme = useTheme();
  const i18n = useI18n();
  const searchBoxProps = useSearchbox();
  const { isRTL } = useLocaleContext();
  const trackLandingPageEvents = useLandingPageEvents();

  return (
    <>
      <Box
        backgroundColor="brand_primary"
        attributes={{ style: { width: "100%", paddingTop: theme.units.spacing_6x, paddingBottom: offset } }}
      >
        <Container style={{ padding: `0 0 ${theme.units.spacing_6x} 0` }}>
          <NoJS />
          <div>
            <Stack gap={2}>
              <Text variant="headline_1" data-testid="home_header" tagName="h1">
                {i18n.trans(t("flights_index_header_compare_book_ease"))}
              </Text>

              <Text variant="body_1">{i18n.trans(t("flights_index_header_discover_next_dream"))}</Text>
            </Stack>
          </div>
        </Container>
      </Box>

      <Box
        attributes={{
          style: {
            width: "100%",
            paddingTop: 0,
            paddingBottom: 0
          }
        }}
      >
        <Container style={{ padding: 0, transform: `translateY(-${offset}px)`, marginBottom: -offset }}>
          <SearchboxController
            i18n={i18n}
            {...searchBoxProps}
            isHeaderFramed={true}
            isRTL={isRTL}
            onSearch={(searchParams) => {
              trackLandingPageEvents("click", "searchbox");
              searchBoxProps.onSearch(searchParams);
            }}
          >
            {() => <SearchBoxVerticalDefault />}
          </SearchboxController>
        </Container>
      </Box>
    </>
  );
};

export default HomeSearchHeader;
