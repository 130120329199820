import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Actionable, Icon, Image, Stack, Text } from "@bookingcom/bui-react";
import { ArrowRightIcon } from "@bookingcom/bui-assets-react/streamline";
import { getFlightsInterlinking } from "store/flightsInterlinking/selector";
import useGlobalContext from "hooks/useGlobalContext";
import { FlightsInterlinkingTriggerIds } from "./useFlightsInterlinkingPanelList";
import {
  LP_CITY_TO_CITY_URL,
  LP_CITY_URL,
  LP_AIRPORT_URL,
  LP_COUNTRY_URL,
  LP_REGION_URL,
  ACCOMMODATIONS_DOMAIN_URL,
  ACCOMMODATIONS_DOMAIN_URL_DQS
} from "../../../constants";
import { IMAGE_STATIC_ASSETS_URL } from "@flights/constants";
import styles from "./FlightsInterlinkingItem.module.css";

type BaseProps = {
  departureTranslatedName: string;
  arrivalTranslatedName: string;
  cityTranslatedName: string;
  countryTranslatedName: string;
  regionTranslatedName: string;
  airportTranslatedName: string;
  iata: string;
  image: string;
};

type PairProps = readonly [
  readonly ["departureTranslatedName", "arrivalTranslatedName", "image"],
  readonly ["airportTranslatedName", "iata", "image"],
  readonly ["cityTranslatedName", "image"],
  readonly ["countryTranslatedName", "image"],
  readonly ["regionTranslatedName", "image"]
];

type Split<T, P extends readonly (readonly (keyof T)[])[]> = {
  [K in P[number] as K[0]]: Record<K[0], T[K[0]]> &
    Record<K[1], T[K[1]]> &
    (K[2] extends keyof T ? Record<K[2], T[K[2]]> : {}) &
    Partial<Record<Exclude<keyof T, K[0] | K[1] | K[2]>, never>>;
}[P[number][0]];

type Props = Split<BaseProps, PairProps> & {
  type: FlightsInterlinkingTriggerIds;
  countryCode?: string;
  countryCodeDeparture?: string;
  countryCodeArrival?: string;
  departurePageName?: string;
  arrivalPageName?: string;
  pageName?: string;
};

const RenderItemContent = ({ image, text, secondaryText }: { image: string; text: string; secondaryText?: string }) => (
  <Stack direction="row" alignItems="center" gap={4} wrap="nowrap">
    <Image
      className={styles.image}
      src={`${IMAGE_STATIC_ASSETS_URL}${image}`}
      borderRadius={200}
      fallback="icon"
      width="64"
      height="64"
    />
    <Stack direction="row" alignItems="center" justifyContent="start" gap={2} className={styles.text}>
      {text ? (
        <Text color="neutral" variant={"strong_1"} className={!secondaryText ? styles.text : ""}>
          {text}
        </Text>
      ) : null}
      {secondaryText ? (
        <>
          <Icon
            size="smallest"
            svg={ArrowRightIcon}
            color="neutral"
            attributes={{ style: { stroke: "currentColor", strokeWidth: 1 } }}
          />{" "}
          <Text color="neutral" variant="strong_1" className={!secondaryText ? styles.text : ""}>
            {secondaryText}
          </Text>
        </>
      ) : null}
    </Stack>
  </Stack>
);

const FlightsInterlinkingItem = ({ flight }: { flight: Props }) => {
  const { env, locale } = useGlobalContext();
  const flightsInterlinking = useSelector(getFlightsInterlinking);
  const departureCountryCode = flightsInterlinking?.departureCountry?.countryCode || "";

  const getUrl = env === "prod" ? ACCOMMODATIONS_DOMAIN_URL : ACCOMMODATIONS_DOMAIN_URL_DQS;

  const { type, image } = flight;

  const { itemUrl, text, secondaryText } = useMemo(() => {
    let itemUrl = "";
    let text = "";
    let secondaryText;

    switch (type) {
      case "citiesToCities":
        itemUrl = `${getUrl}${LP_CITY_TO_CITY_URL}/${flight.countryCodeDeparture}-${flight.departurePageName}-to-${flight.countryCodeArrival}-${flight.arrivalPageName}.${locale}.html`;
        text = flight.departureTranslatedName || "";
        secondaryText = flight.arrivalTranslatedName;
        break;
      case "cities":
        itemUrl = `${getUrl}${LP_CITY_URL}/${flight.countryCode}/${flight.pageName}.${locale}.html`;
        text = flight.cityTranslatedName || "";
        break;
      case "countries":
        itemUrl = `${getUrl}${LP_COUNTRY_URL}/${departureCountryCode}/${flight.countryCode}.${locale}.html`;
        text = flight.countryTranslatedName || "";
        break;
      case "regions":
        itemUrl = `${getUrl}${LP_REGION_URL}/${flight.countryCode}/${flight.pageName}.${locale}.html`;
        text = flight.regionTranslatedName || "";
        break;
      case "airports":
        itemUrl = `${getUrl}${LP_AIRPORT_URL}/${flight.countryCode}/${flight.iata}.${locale}.html`;
        text = flight.airportTranslatedName && flight.iata ? `${flight.airportTranslatedName} (${flight.iata})` : "";
        break;
    }

    return { itemUrl, text, secondaryText };
  }, [type, flight, locale, departureCountryCode, getUrl]);

  const renderItem = useCallback(() => {
    if (!text) return null;

    return <RenderItemContent image={image} text={text} secondaryText={secondaryText} />;
  }, [image, text, secondaryText]);

  return <Actionable href={itemUrl}>{renderItem()}</Actionable>;
};
export default FlightsInterlinkingItem;
