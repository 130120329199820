import React from "react";
import { Actionable, AspectRatio, Button, Card, Image, Stack, Text } from "@bookingcom/bui-react";
import { customGoal, trackWithDefaultStage } from "@flights/et-universal";

import styles from "components/elements/CountrySpotlight/CountrySpotlight.module.css";
import useCountrySpotlightData, { SpotlightItem } from "components/elements/CountrySpotlight/useCountrySpotlightData";
import Container from "components/elements/Container";

const SpotlightV1And2 = () => {
  const {
    spotlightV1andV2Data,
    cardUrlLink,
    buttonUrlLink,
    buttonText,
    headlineText,
    subtitleText,
    isBrCountryExp,
    isNaCountryExp
  } = useCountrySpotlightData();

  const trackCardClick = (id: number) => {
    if (isBrCountryExp) {
      customGoal("flights_web_cat_country_spotlight_br_www_one", id);
    } else {
      customGoal("flights_web_cat_country_spotlight_na_www_one", id);
    }
  };

  const trackButtonClick = () => {
    if (isBrCountryExp) {
      customGoal("flights_web_cat_country_spotlight_br_www_one", 4);
    } else {
      customGoal("flights_web_cat_country_spotlight_na_www_one", 4);
    }
  };

  return (
    <Container pt={8} pb={8}>
      <Stack direction="column">
        <Text variant="headline_2">{headlineText}</Text>
        <Text variant="body_1">{subtitleText}</Text>
      </Stack>
      <Stack direction="row" className={styles.cardsWrapper}>
        {spotlightV1andV2Data.map((item: SpotlightItem) => (
          <Actionable
            key={item.id}
            className={styles.item}
            href={cardUrlLink(`${item?.code}.${item?.type}`, item.country)}
            onClick={() => trackCardClick(item.id)}
          >
            <Card fill>
              <AspectRatio ratio="16:9">
                <Image src={item.image_url} />
              </AspectRatio>
              <Stack className={styles.cardContent} direction="column">
                <Text color="neutral" variant="strong_1">
                  {item.title}
                </Text>
                <Text color="neutral" variant="body_2">
                  {item.subtitle}
                </Text>
                <Text color="neutral" variant="body_1">
                  {item.description}
                </Text>
              </Stack>
            </Card>
          </Actionable>
        ))}
      </Stack>
      {(isBrCountryExp && trackWithDefaultStage("flights_web_cat_country_spotlight_br_www_one", 1) === 2) ||
      (isNaCountryExp && trackWithDefaultStage("flights_web_cat_country_spotlight_na_www_one", 1) === 2) ? (
        <Button size="large" wide={true} className={styles.button} href={buttonUrlLink} onClick={trackButtonClick}>
          {buttonText}
        </Button>
      ) : null}
    </Container>
  );
};

export default SpotlightV1And2;
