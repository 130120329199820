import React, { useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import { t } from "@bookingcom/lingojs-core";
import { Carousel, Stack, Text, Title, useViewport } from "@bookingcom/bui-react";
import { useI18n } from "@bookingcom/lingojs-react";
import type CarouselProps from "@bookingcom/bui-react/components/Carousel";

import { getWeekendGetaways } from "store/weekendGetaways/selectors";
import { PriceDisclaimerDesktop } from "components/elements/PriceDisclaimer/PriceDisclaimer.desktop";
import styles from "./WeekendGetaways.module.css";
import WeekendGetawaysItem from "./WeekendGetawaysItem";
import useUserAgent from "hooks/useUserAgent";
import { WEEKEND_GETAWAYS_DAYS } from "../../../constants";
import { useTrackLandingPageViewport } from "hooks/c360/useLandingPageEvents";

type CarouselSize = React.ComponentProps<typeof CarouselProps>["size"];

const WeekendGetaways = () => {
  const i18n = useI18n();
  const { isSmall, isMedium } = useViewport();
  const { isMobile } = useUserAgent();
  const scrollRef = useRef<HTMLDivElement>(null);

  const weekendGetaways = useSelector(getWeekendGetaways);

  const cityName = weekendGetaways?.[0]?.departureCityName ?? "";

  const carouselSize = useMemo((): CarouselSize => {
    let size: CarouselSize = "medium";
    if (isMedium) {
      size = "large";
    } else if (isSmall) {
      size = "largest";
    }

    return size;
  }, [isSmall, isMedium]);

  useTrackLandingPageViewport(scrollRef, "weekend_getaways");

  if (!weekendGetaways?.length) return null;

  return (
    <div ref={scrollRef}>
      <Title
        title={i18n.trans(
          t("flights_cheap_weekend_carousel_title", {
            variables: { num_days: WEEKEND_GETAWAYS_DAYS },
            num_exception: WEEKEND_GETAWAYS_DAYS
          })
        )}
        subtitle={
          <Stack direction="row" justifyContent="start" alignItems="center" wrap="wrap">
            {i18n.trans(t("flights_cheap_weekend_carousel_subtitle", { variables: { city_name: cityName } }))}{" "}
            {!isMobile ? <PriceDisclaimerDesktop weekendGetaways /> : null}
          </Stack>
        }
        variant="headline_2"
        titleTagName="h2"
      />
      <Carousel
        size={isMobile ? "largest" : carouselSize}
        nextButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_next_button_aria_label"))}
        previousButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_previous_button_aria_label"))}
        className={styles.carousel}
      >
        {weekendGetaways.map((item, idx) => (
          <WeekendGetawaysItem key={idx} item={item} />
        ))}
      </Carousel>
      {isMobile ? (
        <Text variant="small_2" className={styles.disclaimer}>
          {i18n.trans(t("flights_cheap_weekend_carousel_disclaimer"))}
        </Text>
      ) : null}
    </div>
  );
};

export default WeekendGetaways;
