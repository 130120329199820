import React, { useMemo } from "react";
import { format } from "date-fns";

import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

import { OpenSkyFlightResult } from "@flights/types";
import { formattingUtils } from "@flights/web-api-utils-universal";

import useFormatPrice from "hooks/useFormatPrice";
import { useGenerateWeekendGetawayCardUrl } from "components/elements/WeekendGetaways/utils";
import WeekendGetawaysItemInnerDesktop from "./WeekendGetawaysItemInnerDesktop";
import WeekendGetawaysItemInnerMobile from "./WeekendGetawaysItemInnerMobile";
import useUserAgent from "hooks/useUserAgent";

const WeekendGetawaysItem = ({ item }: { item: OpenSkyFlightResult }) => {
  const i18n = useI18n();
  const { formatPrice } = useFormatPrice();
  const { isMobile } = useUserAgent();

  const {
    arrivalCityIata,
    arrivalCityNameTranslated,
    departureCityIata,
    departureCountry,
    currencyCode,
    departureDate,
    outboundDuration,
    pricePerPersonInCurrency,
    returnDepartureDate,
    tripType,
    numberOfAdults,
    cabinClass,
    arrivalCityImageUrl,
    returnDuration
  } = item;

  const maxDuration = Math.max(outboundDuration, returnDuration);
  const duration = formattingUtils.convertMinutesToHours(maxDuration);
  const addSpaceIfHasHours = duration.hours > 0 ? " " : "";

  const durationCopy = useMemo(() => {
    return `${
      duration.hours > 0
        ? i18n.trans(
            t("flights_cheap_weekend_carousel_duration_h", {
              variables: { flight_duration_h: duration.hours },
              num_exception: duration.hours
            })
          )
        : ""
    }${
      duration.minutes > 0
        ? `${addSpaceIfHasHours}${i18n.trans(
            t("flights_cheap_weekend_carousel_duration_m", {
              variables: { flight_duration_m: duration.minutes },
              num_exception: duration.minutes
            })
          )}`
        : ""
    }`;
  }, [addSpaceIfHasHours, duration.hours, duration.minutes, i18n]);

  const price = formatPrice({ currencyCode, nanos: 0, units: pricePerPersonInCurrency }, { decimalPlaces: 0 });

  const linkUrlSR = useGenerateWeekendGetawayCardUrl({
    arrivalCityIata,
    departureCityIata,
    departureCountry,
    departureDate: format(new Date(departureDate), "yyyy-MM-dd"),
    returnDate: format(new Date(returnDepartureDate), "yyyy-MM-dd"),
    tripType,
    numberOfAdults: numberOfAdults.toString(),
    cabinClass,
    pricePerPersonInCurrency
  });

  return isMobile ? (
    <WeekendGetawaysItemInnerMobile
      price={price}
      linkUrlSR={linkUrlSR}
      durationCopy={durationCopy}
      arrivalCityImageUrl={arrivalCityImageUrl}
      arrivalCityNameTranslated={arrivalCityNameTranslated}
      departureDate={departureDate}
      returnDepartureDate={returnDepartureDate}
    />
  ) : (
    <WeekendGetawaysItemInnerDesktop
      price={price}
      linkUrlSR={linkUrlSR}
      durationCopy={durationCopy}
      arrivalCityImageUrl={arrivalCityImageUrl}
      arrivalCityNameTranslated={arrivalCityNameTranslated}
      departureDate={departureDate}
      returnDepartureDate={returnDepartureDate}
    />
  );
};

export default WeekendGetawaysItem;
