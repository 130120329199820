import { useCallback, useMemo } from "react";
import { useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import { UISearchLocation } from "@flights/types";
import { trackWithDefaultStage } from "@flights/et-universal";
import { IMAGE_STATIC_ASSETS_URL } from "@flights/constants";

import useGlobalContext from "hooks/useGlobalContext";
import useCreateUrl from "hooks/useCreateUrl";
import useSearchbox from "hooks/useSearchbox";

export type SpotlightItem = {
  id: number;
  title: string;
  code: string;
  type: UISearchLocation["type"];
  regionName: string;
  country: string;
  subtitle: string;
  description: string;
  image_url: string;
  image_alt: string;
};

const NA_COUNTRIES = ["us", "ca", "mx"];
const brazilianRegionNames = ["RJ", "BA", "AM"];
const naRegionNames = ["NY", "FL", "CA", "LA"];

const useCountrySpotlightData = () => {
  const i18n = useI18n();
  const { ipCountry = "", regionCode } = useGlobalContext();
  const { createUrlWithApiPrefix } = useCreateUrl();
  const searchBoxProps = useSearchbox();

  const isNaCountry = NA_COUNTRIES.includes(ipCountry);

  const isNaCountryExp = isNaCountry && trackWithDefaultStage("flights_web_cat_country_spotlight_na_www_one", 1);
  const isBrCountryExp =
    !isNaCountryExp && !isNaCountry && trackWithDefaultStage("flights_web_cat_country_spotlight_br_www_one", 1);

  const fromSegment = searchBoxProps.initialValue.searchSegments[0].from[0];
  const { departureDate = "", returnDate = "" } = searchBoxProps.initialValue.searchSegments[0];
  const { adults, cabinClass, children, type } = searchBoxProps.initialValue;

  const brazilData: SpotlightItem[] = [
    {
      id: 1,
      title: i18n.trans(t("flights_cat_country_brazil_rio_city_name")),
      code: "RIO",
      type: "CITY",
      regionName: "RJ",
      country: "BR",
      subtitle: i18n.trans(t("flights_cat_brazil_rio_line")),
      description: i18n.trans(t("flights_cat_country_brazil_rio_body")),
      image_url: `${IMAGE_STATIC_ASSETS_URL}/xdata/images/xphoto/696x466/474282318.webp?k=9b214fa5cd80a61f55862df24364f976467ae335384f15c0f608032bfa0f6907&o=`,
      image_alt: i18n.trans(t("flights_a11y_image_alt_rio_2"))
    },
    {
      id: 2,
      title: i18n.trans(t("flights_cat_country_brazil_salvador_city_name")),
      code: "SSA",
      type: "AIRPORT",
      regionName: "BA",
      country: "BR",
      subtitle: i18n.trans(t("flights_cat_brazil_salvador_line")),
      description: i18n.trans(t("flights_cat_country_brazil_salvador_body")),
      image_url: `${IMAGE_STATIC_ASSETS_URL}/xdata/images/xphoto/696x466/474282333.webp?k=af4b7c8956f13b036a44049e02942eed45f5944c652b16775b608304658b2343&o=`,
      image_alt: i18n.trans(t("flights_a11y_image_alt_salvador"))
    },
    {
      id: 3,
      title: i18n.trans(t("flights_cat_country_brazil_manaus_city_name")),
      code: "MAO",
      type: "AIRPORT",
      regionName: "AM",
      country: "BR",
      subtitle: i18n.trans(t("flights_cat_brazil_manaus_line")),
      description: i18n.trans(t("flights_cat_country_brazil_manaus_body")),
      image_url: `${IMAGE_STATIC_ASSETS_URL}/xdata/images/xphoto/696x466/474282321.webp?k=d183b85aa744dc78557bbe0a71d70fff6a2c8fc83be23c8076926589759d61f4&o=`,
      image_alt: i18n.trans(t("flights_a11y_image_alt_manaus"))
    },
    {
      id: 4,
      title: i18n.trans(t("flights_cat_country_brazil_fortaleza_city_name")),
      code: "FOR",
      type: "AIRPORT",
      regionName: "CE",
      country: "BR",
      subtitle: i18n.trans(t("flights_cat_brazil_forta_line")),
      description: i18n.trans(t("flights_cat_country_brazil_fortaleza_body")),
      image_url: `${IMAGE_STATIC_ASSETS_URL}/xdata/images/xphoto/696x466/474692368.webp?k=5f7589476664d160621d36efab8777b2d207f3f22adb706030f43ac85f14dc32&o=`,
      image_alt: i18n.trans(t("flights_a11y_image_alt_fortaleza"))
    }
  ];

  const naData: SpotlightItem[] = [
    {
      id: 1,
      title: i18n.trans(t("flights_cat_country_us_ny_city_name")),
      code: "NYC",
      type: "CITY",
      regionName: "NY",
      country: "US",
      subtitle: i18n.trans(t("flights_cat_us_ny_line")),
      description: i18n.trans(t("flights_cat_country_us_ny_body")),
      image_url: `${IMAGE_STATIC_ASSETS_URL}/xdata/images/xphoto/696x466/474288336.webp?k=5b99b2610d8493fc974824d173000bb740f0470dae94dd37c4581b222a26c5bf&o=`,
      image_alt: i18n.trans(t("flights_a11y_image_alt_nyc"))
    },
    {
      id: 2,
      title: i18n.trans(t("flights_cat_country_us_miami_city_name")),
      code: "MIA",
      type: "CITY",
      regionName: "FL",
      country: "US",
      subtitle: i18n.trans(t("flights_cat_us_miami_line")),
      description: i18n.trans(t("flights_cat_country_us_miami_body")),
      image_url: `${IMAGE_STATIC_ASSETS_URL}/xdata/images/xphoto/696x466/474288319.webp?k=579c25e606398dcfcca1e11e7fa026e4f5928d7afde5cbc7428911ca59b2b6d5&o=`,
      image_alt: i18n.trans(t("flights_a11y_image_alt_miami"))
    },
    {
      id: 3,
      title: i18n.trans(t("flights_cat_country_us_norleans_city_name")),
      code: "MSY",
      type: "AIRPORT",
      regionName: "LA",
      country: "US",
      subtitle: i18n.trans(t("flights_cat_us_norleans_line")),
      description: i18n.trans(t("flights_cat_country_us_norleans_body")),
      image_url: `${IMAGE_STATIC_ASSETS_URL}/xdata/images/xphoto/696x466/474288315.webp?k=848252599344d570891fd36815a6f66c264e702b638d8cdf1d1c064d70741a85&o=`,
      image_alt: i18n.trans(t("flights_a11y_image_alt_norleans"))
    },
    {
      id: 4,
      title: i18n.trans(t("flights_cat_country_us_sanfran_city_name")),
      code: "SFO",
      type: "AIRPORT",
      regionName: "CA",
      country: "US",
      subtitle: i18n.trans(t("flights_cat_us_sanfran_line")),
      description: i18n.trans(t("flights_cat_country_us_sanfran_body")),
      image_url: `${IMAGE_STATIC_ASSETS_URL}/xdata/images/xphoto/696x466/474692358.webp?k=f19deaeab8a3550c0205767a89eb826a68c57091553f3581c0a3134fd4d82cf3&o=`,
      image_alt: i18n.trans(t("flights_a11y_image_alt_sanfran"))
    }
  ];

  const spotlightV3BRData = {
    headline: i18n.trans(t("flights_cat_country_spotlight_header")),
    subtitle: i18n.trans(t("flights_cat_country_spotlight_subheader")),
    country: i18n.trans(t("flights_cat_country_spotlight_destination_explore_brazil_cta")),
    attributes: i18n.trans(t("flights_cat_country_spotlight_destination_subtitle_brazil")),
    description: i18n.trans(t("flights_cat_country_spotlight_destination_body_1_brazil")),
    cta: i18n.trans(t("flights_cat_country_spotlight_destination_explore_brazil_cta")),
    image_url: `${IMAGE_STATIC_ASSETS_URL}/xdata/images/xphoto/1000x431/474284200.webp?k=f40d494835bee9ec4c30ca62747034236d22c6446e6b636830408e8c7eaa39b6&o=`
  };

  const spotlightV3NAData = {
    headline: i18n.trans(t("flights_cat_country_spotlight_header")),
    subtitle: i18n.trans(t("flights_cat_country_spotlight_subheader")),
    country: i18n.trans(t("flights_cat_country_spotlight_destination_explore_us_cta")),
    attributes: i18n.trans(t("flights_cat_country_spotlight_destination_subtitle_us")),
    description: i18n.trans(t("flights_cat_country_spotlight_destination_body_1_us")),
    cta: i18n.trans(t("flights_cat_country_spotlight_destination_title_us")),
    image_url: `${IMAGE_STATIC_ASSETS_URL}/xdata/images/xphoto/1000x431/474288336.webp?k=5b99b2610d8493fc974824d173000bb740f0470dae94dd37c4581b222a26c5bf&o=`
  };

  const createUrlWithParams = useCallback(
    (toSegment: string, toCountry: string) => {
      if (!fromSegment) {
        return "";
      }

      const urlParams = new URLSearchParams(window.location.search);
      const from = `${fromSegment?.code}.${fromSegment?.type}`;

      const commonParams = {
        ca_source: isNaCountryExp ? "country_spotlight_na" : "country_spotlight_br",
        type,
        adults: adults.toString(),
        cabinClass,
        children: children.join(","),
        from,
        to: toSegment,
        fromCountry: fromSegment.country,
        toCountry,
        depart: departureDate,
        return: returnDate
      };

      Object.entries(commonParams).forEach(([key, value]) => {
        urlParams.set(key, value);
      });

      return createUrlWithApiPrefix(`/flights/${from}-${toSegment}/?${urlParams}${window.location.hash}`);
    },
    [fromSegment, isNaCountryExp, type, adults, cabinClass, children, departureDate, returnDate, createUrlWithApiPrefix]
  );

  const cardUrlLink = useCallback(
    (toSegmentSimplified: string, toCountry: string) => {
      return createUrlWithParams(toSegmentSimplified, toCountry);
    },
    [createUrlWithParams]
  );

  const spotlightV1andV2Data = isNaCountryExp
    ? NA_COUNTRIES.includes(ipCountry) && regionCode && naRegionNames.includes(regionCode)
      ? naData.filter((item) => item.regionName !== regionCode)
      : naData.slice(0, 3)
    : ipCountry === "br" && regionCode && brazilianRegionNames.includes(regionCode)
    ? brazilData.filter((item) => item.regionName !== regionCode)
    : brazilData.slice(0, 3);

  const buttonUrlLink = useMemo(() => {
    const to = spotlightV1andV2Data.map((item) => `${item.code}.${item.type}`).join("_");
    const toCountry = spotlightV1andV2Data.map((item) => item.country).join(",");
    return createUrlWithParams(to, toCountry);
  }, [createUrlWithParams, spotlightV1andV2Data]);

  const buttonText = isNaCountryExp
    ? i18n.trans(t("flights_cat_country_us_explore_cta"))
    : i18n.trans(t("flights_cat_country_brazil_explore_cta"));

  const isVariantOneOrTwo =
    (isBrCountryExp && trackWithDefaultStage("flights_web_cat_country_spotlight_br_www_one", 1) === 1) ||
    (isNaCountryExp && trackWithDefaultStage("flights_web_cat_country_spotlight_na_www_one", 1) === 1) ||
    (isBrCountryExp && trackWithDefaultStage("flights_web_cat_country_spotlight_br_www_one", 1) === 2) ||
    (isNaCountryExp && trackWithDefaultStage("flights_web_cat_country_spotlight_na_www_one", 1) === 2);

  const isVariantThree =
    (isBrCountryExp && trackWithDefaultStage("flights_web_cat_country_spotlight_br_www_one", 1) === 3) ||
    (isNaCountryExp && trackWithDefaultStage("flights_web_cat_country_spotlight_na_www_one", 1) === 3);

  const headlineText = isBrCountryExp
    ? i18n.trans(t("flights_cat_country_header_brazil"))
    : i18n.trans(t("flights_cat_country_header_us"));
  const subtitleText = isBrCountryExp
    ? i18n.trans(t("flights_cat_country_description_brazil"))
    : i18n.trans(t("flights_cat_country_description_us"));

  return {
    cardUrlLink,
    buttonUrlLink,
    spotlightV1andV2Data,
    spotlightV3Data: isNaCountryExp ? spotlightV3NAData : spotlightV3BRData,
    isNaCountryExp,
    isBrCountryExp,
    buttonText,
    isVariantOneOrTwo,
    isVariantThree,
    headlineText,
    subtitleText
  };
};

export default useCountrySpotlightData;
