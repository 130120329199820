import React from "react";
import { Button, Card, Image, Stack, Text } from "@bookingcom/bui-react";
import Container from "components/elements/Container";
import useCountrySpotlightData from "components/elements/CountrySpotlight/useCountrySpotlightData";
import { customGoal } from "@flights/et-universal";
import styles from "./SpotlightV3.module.css";

const SpotlightV3 = () => {
  const { buttonUrlLink, spotlightV3Data, isBrCountryExp } = useCountrySpotlightData();

  const trackClick = () => {
    if (isBrCountryExp) {
      customGoal("flights_web_cat_country_spotlight_br_www_one", 4);
    } else {
      customGoal("flights_web_cat_country_spotlight_na_www_one", 4);
    }
  };

  return (
    <Container pt={8} pb={8}>
      <Stack direction="column">
        <Text variant="headline_2">{spotlightV3Data.headline}</Text>
        <Text variant="body_1">{spotlightV3Data.subtitle}</Text>
      </Stack>
      <Card fill className={styles.card}>
        <Stack direction="row" gap={4} alignItems="stretch">
          <Image src={spotlightV3Data.image_url} width="50%" className={styles.image} />
          <Stack.Item grow className={styles.content}>
            <Text variant="strong_1">{spotlightV3Data.country}</Text>
            <Text variant="body_2">{spotlightV3Data.attributes}</Text>
            <Text variant="body_1" className={styles.description}>
              {spotlightV3Data.description}
            </Text>
            <Button size="medium" className={styles.button} href={buttonUrlLink} onClick={trackClick}>
              {spotlightV3Data.cta}
            </Button>
          </Stack.Item>
        </Stack>
      </Card>
    </Container>
  );
};

export default SpotlightV3;
